<template>
  <div
    v-if="isShowing"
    ref="interactElement"
    :class="{
      isAnimating: isInteractAnimating,
      isCurrent: isCurrent,
      putToBottom: isOnEnd
    }"
    class="card"
    :style="{ transform: transformString }"
  >
    <img v-bind:src="img" v-if="img" />
    <h4>{{title}}</h4>
      <hr v-if="title">
    <p>{{subtitle}}</p>
    <div class="social">
        <a v-for="(item, index) in links" :key="index" v-bind:href="item.url"><span v-html="item.content"></span></a>
    </div>
    <div class="content">
        <ul>
            <li v-for="(item, index) in contents" :key="index">
                <span>{{item}}</span>
            </li>
        </ul>
    </div>
    <transition name="slide-fade" mode="out-in">
      <p class="quote" v-html="quote" :key="quote"></p>
    </transition>
  </div>
</template>

<script>
import interact from "interact.js";
const ACCEPT_CARD = "cardAccepted";
const REJECT_CARD = "cardRejected";
const SKIP_CARD = "cardSkipped";

export default {
  static: {
    interactMaxRotation: 15,
    interactOutOfSightXCoordinate: 500,
    interactOutOfSightYCoordinate: 600,
    interactYThreshold: 150,
    interactXThreshold: 100
  },

  props: {
    id: Number,
    img: String,
    title: String,
    subtitle: String,
    links: Array,
    contents: Array,
    quotes: Array,
    isCurrent: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      quote: "",
      isShowing: true,
      isOnEnd: false,
      isInteractAnimating: true,
      isInteractDragged: null,
      interactPosition: {
        x: 0,
        y: 0,
        rotation: 0
      }
    };
  },

  computed: {
    transformString() {
      if (!this.isInteractAnimating || this.isInteractDragged) {
        const { x, y, rotation } = this.interactPosition;
        return `translate3D(${x}px, ${y}px, 0) rotate(${rotation}deg)`;
      }

      return null;
    }
  },

  mounted() {
    if(this.quotes){
      let $vm = this;
      $vm.quote = $vm.quotes[0];
      setInterval(function(){
        let index = $vm.getRandomInt(0, $vm.quotes.length);
        console.log(index)
        $vm.quote = $vm.quotes[index];
      }, 2000);
    }
    const element = this.$refs.interactElement;

    interact(element).draggable({
      onstart: () => {
        this.isInteractAnimating = false;
      },

      onmove: event => {
        const {
          interactMaxRotation,
          interactXThreshold
        } = this.$options.static;
        const x = this.interactPosition.x + event.dx;
        const y = this.interactPosition.y + event.dy;

        let rotation = interactMaxRotation * (x / interactXThreshold);

        if (rotation > interactMaxRotation) rotation = interactMaxRotation;
        else if (rotation < -interactMaxRotation)
          rotation = -interactMaxRotation;

        this.interactSetPosition({ x, y, rotation });
      },

      onend: () => {
        const { x, y } = this.interactPosition;
        const { interactXThreshold, interactYThreshold } = this.$options.static;
        this.isInteractAnimating = true;

        if (x > interactXThreshold) this.playCard(ACCEPT_CARD);
        else if (x < -interactXThreshold) this.playCard(REJECT_CARD);
        else if (y > interactYThreshold) this.playCard(SKIP_CARD);
        else this.resetCardPosition();
      }
    });
  },

  beforeDestroy() {
    interact(this.$refs.interactElement).unset();
  },

  methods: {
    
    hideCard() {
      
      setTimeout(() => {
        //set card to the bottom deck
        this.isOnEnd = true;
        //swipe back to the deck position
        this.resetCardPosition();
        //call hide card
        this.$emit("hideCard", this.card);
        //finally hide the card
        this.lastCard();
      }, 50);
    },

    playCard(interaction) {
      const {
        interactOutOfSightXCoordinate,
        interactOutOfSightYCoordinate,
        interactMaxRotation
      } = this.$options.static;

      this.interactUnsetElement();

      switch (interaction) {
        case ACCEPT_CARD:
          this.interactSetPosition({
            x: interactOutOfSightXCoordinate,
            rotation: interactMaxRotation
          });
          this.$emit(ACCEPT_CARD);
          break;
        case REJECT_CARD:
          this.interactSetPosition({
            x: -interactOutOfSightXCoordinate,
            rotation: -interactMaxRotation
          });
          this.$emit(REJECT_CARD);
          break;
        case SKIP_CARD:
          this.interactSetPosition({
            y: interactOutOfSightYCoordinate
          });
          this.$emit(SKIP_CARD);
          break;
      }

      this.hideCard();
    },

    interactSetPosition(coordinates) {
      const { x = 0, y = 0, rotation = 0 } = coordinates;
      this.interactPosition = { x, y, rotation };
    },

    interactUnsetElement() {
      interact(this.$refs.interactElement).unset();
      this.isInteractDragged = true;
    },

    resetCardPosition() {
      this.interactSetPosition({ x: 0, y: 0, rotation: 0 });
    },

    lastCard(){
      setTimeout(() => {
        this.isShowing = false;
      }, 300)
    },

    getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
  }
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Quicksand');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.4.0/css/font-awesome.min.css');
@import "../styles/index.scss";

$cardsTotal: 4;
$cardsWidth: 280px;
$cardsPositionOffset: 55vh * 0.06;
$cardsScaleOffset: 0.09;
$defaultTranslation: $cardsPositionOffset * $cardsTotal;
$defaultScale: 1 - ($cardsScaleOffset * $cardsTotal);
$lastCardScale: 1 - ($cardsScaleOffset * 1);
$fs-card-title: 1.125em;

.card {
  @include card();
  @include absolute(0);
  @include sizing(280px 440px);

  @include after() {
    @include sizing(21px 3px);
    @include absolute(right 0 bottom 11px left 0);

    margin: auto;
    border-radius: 100px;
    background: rgba($c-black, 0.3);
  }

  display: block;
  margin: auto;
  font-size: $fs-h2;
  font-weight: $fw-bold;
  color: #000;
  background: #ffffff;
  opacity: 0;
  transform: translateY($defaultTranslation) scale($defaultScale);
  transform-origin: 50%, 100%;
  box-shadow: 0 10px 12px 0 rgb(136 152 170 / 30%);
//   user-select: none;
//   pointer-events: none;
  will-change: transform, opacity;
  padding: 1.5rem;
  &.isCurrent {
    pointer-events: auto;
  }

  &.isAnimating {
    transition: transform 0.7s $ease-out-back;
  }
}

.cardTitle {
  margin: 0 0 15px;
  font-size: $fs-card-title;
}

@for $i from 1 through $cardsTotal {
  $index: $i - 1;
  $translation: $cardsPositionOffset * $index;
  $scale: 1 - ($cardsScaleOffset * $index);

  .card:nth-child(#{$i}) {
    z-index: $cardsTotal - $index;
    opacity: 1;
    transform: translateY($translation) scale($scale);

    @if $i != 1 {
      background-image: none;

      @include after() {
        @include sizing(0 0);
      }
    }
  }
}

img {
    width: 65%;
    margin: 20px 0;
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
}

hr {
    margin-top: 10px;
    margin-bottom: 25px;
    border: .5px solid #eee;
    margin-left: -24px;
    width: calc(100% + 47px);
}

h4 {
    padding: 0 20px;
    margin-bottom: 30px;
    font-weight: bold;
    letter-spacing: .1em;
}

h3 {
    padding: 0 20px;
    margin-bottom: 10px;
    font-weight: bold;
}

.social{
    margin-top: 10px;
}

a{
    color: #5E81AC;
    padding: 0 20px;
    font-weight: normal;
    line-height: 30px;
}

ul{
	list-style-type: none;
	margin: 0;
    padding: 0;
}
li {
  list-style: none;
	margin-bottom: 5px;
	letter-spacing: .1em;
}

li span, p span {
	background: linear-gradient(180deg, white 70%, #8FBCBB 30%);
}

.quote{
  position: relative;
  margin-top: 65%;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.putToBottom{
  transform: translateY(6.6vh) scale(0.82) !important;
  z-index: 0 !important;
}
</style>
