<template>
  <div class="cards">
    <Card
      v-for="(item, index) in cards"
      :key="index"
      :item="item"
      :img="item.img" 
      :title="item.title" 
      :subtitle="item.subtitle" 
      :links="item.links"
      :contents="item.contents"
      :quotes="item.quotes"
      :is-current="index === 0"
      @cardAccepted="$emit('cardAccepted');"
      @cardRejected="$emit('cardRejected');"
      @cardSkipped="$emit('cardSkipped');"
      @hideCard="$emit('hideCard');"
    />
  </div>
</template>

<script>
import Card from "@/components/Card";

export default {
  components: {
    Card
  },
  props: {
    cards: {
      type: Array,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.cards {
  position: relative;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
}
</style>
