<template>
  <div id="app">
    <CardsStack
        :cards="dataCards"
        :key="resetKey"
        @cardAccepted="handleCardAccepted"
        @cardRejected="handleCardRejected"
        @cardSkipped="handleCardSkipped"
        @hideCard="removeCardFromDeck"
		/>
    
    
  </div>
</template>

<script>
import CardsStack from "./components/CardsStack";


export default {
  name: "App",
  components: {
    CardsStack
  },

  data() {
    return {
      quote: "",
      iteration:0,
      iteration2:0,
      resetKey: 0,
      quotes:[
        "If you tell the <span style='background-color: #92B558; color: #f7f7f7; padding: 0 3px; border-radius: 3px'>truth</span>, you don't have to remember anything. <br> • <b>Mark Twain</b>", 
"A friend is someone who knows all about you and still <span style='background-color: #DC4C46; color: #f7f7f7;  padding: 0 3px; border-radius: 3px'>loves</span> you. <br> • <b>Elbert Hubbard</b>",
"<span style='background-color: #672E3B; color: #f7f7f7;  padding: 0 3px; border-radius: 3px'>Nothing</span> prepared me for what the privilege of being yours would do. <br> • <b>Sleeping at Last</b>",
"Always <span style='background-color: #F3D6E4; color: #f7f7f7; padding: 0 3px; border-radius: 3px'>forgive</span> your enemies; nothing annoys them so much. <br> • <b>Oscar Wilde</b>",
"Don't cry because it's over, smile because it <span style='background-color: #223A5E; color: #f7f7f7; padding: 0 3px; border-radius: 3px'>happened</span>. <br> • <b>Dr. Seuss</b>",
"So many books, so little <span style='background-color: #005960; color: #f7f7f7; padding: 0 3px; border-radius: 3px'>time</span>. <br> • <b>Frank Zappa</b>",
"I solemnly swear that <span style='background-color: #9C9A40; color: #f7f7f7; padding: 0 3px; border-radius: 3px'>I am</span> up to no good. <br> •  <b>J.K. Rowling</b>",
"Be the change that <span style='background-color: #4F84C4; color: #f7f7f7; padding: 0 3px; border-radius: 3px'>you</span> wish to see in the world. <br> • <b>Mahatma Gandhi</b>",
"In three words I can sum up <span style='background-color: #578CA9; color: #f7f7f7; padding: 0 3px; border-radius: 3px'>everything</span> I've learned about life: it goes on. <br> • <b>Robert Frost</b>",
"Not all those who wander are <span style='background-color: #F2552C; color: #f7f7f7; padding: 0 3px; border-radius: 3px'>lost</span>. <br> • <b>J.R.R. Tolkien</b>"
      ],
      rawCards: [
        {
            id: 0,
            img: 'https://satrya.id/source/assets/profile-sqc.png',
            title: 'SATRIA PRAYUDI',
            subtitle: 'Currently I conceptualize, mock-up, wire-frame, prototype, and develop final products',
            links: [
                {url: 'https://github.com/satryacode', content: "<i class='fa fa-github'></i>"}, 
                {url: 'http://linkedin.com/in/satryacode', content: "<i class='fa fa-linkedin'></i>"}, 
                {url: 'http://twitter.com/satryacode', content: "<i class='fa fa-twitter'></i>"}, 
                {url: 'http://facebook.com/pixlrs', content: "<i class='fa fa-facebook'></i>"}
            ]
        },
        {
            id: 1,
            title: 'EXPERIENCES',
            contents: ["AWS/Google Cloud Services", "DevSecOps", "Mobile and Web Developer","Cryptographer","Application Architect","e-KTP & Fingerprint Matching","Bank Statement Recognizing (OCR)","QR Code Parser","Sales Tools","Performance Activity Monitoring","Digital Attendance"]
        },
        {
            id: 2,
            title: 'SKILLS',
            contents: ["Python", "Go","C#","Flutter","Javascript","PHP","Git","Docker"]
        },
        {
            id: 3,
            title: 'CONTACT',
            subtitle: "Please fell free to contact me satryacode@gmail.com"
        },
        {
            quotes: [
        "If you tell the <span style='background-color: #92B558; color: #f7f7f7; padding: 0 3px; border-radius: 3px'>truth</span>, you don't have to remember anything. <br> • <b>Mark Twain</b>", 
"A friend is someone who knows all about you and still <span style='background-color: #DC4C46; color: #f7f7f7;  padding: 0 3px; border-radius: 3px'>loves</span> you. <br> • <b>Elbert Hubbard</b>",
"<span style='background-color: #672E3B; color: #f7f7f7;  padding: 0 3px; border-radius: 3px'>Nothing</span> prepared me for what the privilege of being yours would do. <br> • <b>Sleeping at Last</b>",
"Always <span style='background-color: #F3D6E4; color: #f7f7f7; padding: 0 3px; border-radius: 3px'>forgive</span> your enemies; nothing annoys them so much. <br> • <b>Oscar Wilde</b>",
"Don't cry because it's over, smile because it <span style='background-color: #223A5E; color: #f7f7f7; padding: 0 3px; border-radius: 3px'>happened</span>. <br> • <b>Dr. Seuss</b>",
"So many books, so little <span style='background-color: #005960; color: #f7f7f7; padding: 0 3px; border-radius: 3px'>time</span>. <br> • <b>Frank Zappa</b>",
"I solemnly swear that <span style='background-color: #9C9A40; color: #f7f7f7; padding: 0 3px; border-radius: 3px'>I am</span> up to no good. <br> •  <b>J.K. Rowling</b>",
"Be the change that <span style='background-color: #4F84C4; color: #f7f7f7; padding: 0 3px; border-radius: 3px'>you</span> wish to see in the world. <br> • <b>Mahatma Gandhi</b>",
"In three words I can sum up <span style='background-color: #578CA9; color: #f7f7f7; padding: 0 3px; border-radius: 3px'>everything</span> I've learned about life: it goes on. <br> • <b>Robert Frost</b>",
"Not all those who wander are <span style='background-color: #F2552C; color: #f7f7f7; padding: 0 3px; border-radius: 3px'>lost</span>. <br> • <b>J.R.R. Tolkien</b>"
      ]
        }
      ],
      dataCards: []
    };
  },

  methods: {
    handleCardAccepted() {
      console.log("handleCardAccepted");
    },
    handleCardRejected() {
      console.log("handleCardRejected");
    },
    handleCardSkipped() {
      console.log("handleCardSkipped");
    },
    removeCardFromDeck() {
        if(this.iteration == this.rawCards.length){
          this.iteration = 0;
        }
        this.dataCards.push(this.rawCards[this.iteration]);
        this.iteration++;
        console.log("card removed from stacks", this.iteration, this.resetKey);
    },
    loadCards(){
      let $vm = this;
      console.log('iteration', $vm.iteration2, $vm.rawCards.length);
      setTimeout(function() {
          $vm.dataCards.push($vm.rawCards[$vm.iteration2]);
          $vm.iteration2++;
          if ($vm.iteration2 < $vm.rawCards.length) {
            $vm.loadCards();
          }
        }, 50)
    }
  },

  mounted(){
    let $vm = this;
    $vm.loadCards();
  }
};
</script>

<style lang="scss">
@import "./styles/mixins.scss";

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  background: #D5DDE0;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.4em;
  overflow: hidden;
}

#app {
    font-family: 'Quicksand', Helvetica, Arial, sans-serif;
    text-align: center;
}
</style>
